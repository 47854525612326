import React from 'react';

import Link from '../editor/Link'

const BHotNewsBarEditor = props => {

    // render
    return (
        <div>
            {props.children}
            <Link value={props.settings.link} />
            <div className="form-group">
                <label className="col-sm-3 control-label">Nagłówek</label>
                <div className="col-sm-9">
                    <input type="text" name="header" className="form-control" defaultValue={props.settings.header} placeholder="" />
                </div>
            </div>
            <div className="form-group">
                <label className="col-sm-3 control-label">Kolor nagłówka (hex)</label>
                <div className="col-sm-2">
                    <input type="text" name="headerColor" className="form-control" defaultValue={props.settings.headerColor} placeholder="#ffffff" />
                </div>
            </div>
            <div className="form-group">
                <label className="col-sm-3 control-label">Tytuł artykułu</label>
                <div className="col-sm-9">
                    <input type="text" name="title_normal" className="form-control" defaultValue={props.settings.title_normal} placeholder="" />
                </div>
            </div>
        </div>
    );
}


export default BHotNewsBarEditor;