export const plugins = [
    {
        setupPrebid() {
            window.pbjs.bidderSettings = {
                ...(window.prebidConfig?.bidderSettings ?? {}),
                adform: {
                    ...(window.prebidConfig?.bidderSettings?.adform ?? {}),
                    bidCpmAdjustment: function (bid) {
                        return 0.9 * bid;
                    }
                },
                appnexus: {
                    ...(window.prebidConfig?.bidderSettings?.appnexus ?? {}),
                    bidCpmAdjustment: function (bid) {
                        return 0.86 * bid;
                    }
                },
                openx: {
                    ...(window.prebidConfig?.bidderSettings?.openx ?? {}),
                    bidCpmAdjustment: function (bid) {
                        return bid;
                    }
                },
                rubicon: {
                    ...(window.prebidConfig?.bidderSettings?.rubicon ?? {}),
                    bidCpmAdjustment: function (bid) {
                        return 0.92 * bid;
                    }
                },
                smartadserver: {
                    ...(window.prebidConfig?.bidderSettings?.smartadserver ?? {}),
                    bidCpmAdjustment: function (bid) {
                        return 0.9 * bid;
                    }
                },
                rtbhouse: {
                    ...(window.prebidConfig?.bidderSettings?.rtbhouse ?? {}),
                    bidCpmAdjustment: function (bid) {
                        return bid;
                    }
                },
                criteo: {
                    ...(window.prebidConfig?.bidderSettings?.criteo ?? {}),
                    bidCpmAdjustment: function (bid) {
                        return bid;
                    }
                },
                pubmatic: {
                    ...(window.prebidConfig?.bidderSettings?.pubmatic ?? {}),
                    bidCpmAdjustment: function (bid) {
                        return 0.8 * bid;
                    }
                },
                ix: {
                    ...(window.prebidConfig?.bidderSettings?.ix ?? {}),
                    bidCpmAdjustment: function (bid) {
                        return bid;
                    }
                }
            }
        }
    },
    {
        setupGpt() {
            window.AD_SLOTS = this.slots
            // window.googletag.cmd.push(() => {
            //     window.googletag.pubads().addEventListener('slotRenderEnded', e => {
            //         // console.group('rendered: ')
            //         // console.log(e.slot.getAdUnitPath())
            //         // console.log(e.slot.getSlotElementId())
            //         // console.groupEnd()
            //         const slotId = e.slot.getSlotElementId()
            //         // console.log(`slot rednered ${slotId}`)
            //         if (window.AD_CONTAINER_CONFIG[slotId]?.capping?.enabled) {
            //             const d = new Date()
            //             d.setMinutes(d.getMinutes() + window.AD_CONTAINER_CONFIG[slotId]?.capping?.time)

            //             if (!document.cookie.split(';').some((item) => item.trim().startsWith(slotId))) {
            //                 document.cookie = slotId + "=true; path=/; expires=" + d.toUTCString() + "; secure; samesite=lax";
            //             }
            //         }
            //     })
            // })
        }
    }
]