import React from 'react';

import Box from '../Box'

import { keyframes } from "@stitches/react";
import { styled } from "../../../stitches.config";


export default props => {

    return (
        <Root className={props.settings.b1?.title_normal ? "b-hot-news-bar" : "b-hot-news-bar b-hot-news-bar--blocked"}>
            <section className="container" data-tb-region={props.blockSettings.tb_region ? props.blockSettings.tb_region + '_newsbar' : undefined}>
                <Wrapper className="first-wrap">
                    <Box {...props} id="b1" type="BHotNewsBar" settings={props.settings.b1} />
                    <Box {...props} id="b2" type="BHotNewsBar" settings={props.settings.b2} />
                    <Box {...props} id="b3" type="BHotNewsBar" settings={props.settings.b3} />
                    <Box {...props} id="b4" type="BHotNewsBar" settings={props.settings.b4} />
                    <Box {...props} id="b5" type="BHotNewsBar" settings={props.settings.b5} />
                </Wrapper>
                <Wrapper>
                    <Box {...props} id="b1" type="BHotNewsBar" settings={props.settings.b1} />
                    <Box {...props} id="b2" type="BHotNewsBar" settings={props.settings.b2} />
                    <Box {...props} id="b3" type="BHotNewsBar" settings={props.settings.b3} />
                    <Box {...props} id="b4" type="BHotNewsBar" settings={props.settings.b4} />
                    <Box {...props} id="b5" type="BHotNewsBar" settings={props.settings.b5} />
                </Wrapper>
                <Wrapper className="delay-wrap">
                    <Box {...props} id="b1" type="BHotNewsBar" settings={props.settings.b1} />
                    <Box {...props} id="b2" type="BHotNewsBar" settings={props.settings.b2} />
                    <Box {...props} id="b3" type="BHotNewsBar" settings={props.settings.b3} />
                    <Box {...props} id="b4" type="BHotNewsBar" settings={props.settings.b4} />
                    <Box {...props} id="b5" type="BHotNewsBar" settings={props.settings.b5} />
                </Wrapper>
            </section>
        </Root>
    );
}

const scroll = keyframes({
    "0%": { transform: "translate(100%, 0)" },
    "100%": { transform: "translate(-100%, 0)" },
});

const scrollFirst = keyframes({
    "0%": { transform: "translate(0%, 0)" },
    "100%": { transform: "translate(-100%, 0)" },
});


const Root = styled("div", {
    "& .container": {
        overflow: "hidden",
        background: "$grayscale100",
        height: "48px",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    "& .first-wrap": {
        animationDelay: "0s",
        animation: `${scrollFirst} 60s linear infinite`,
        transform: "translateX(0%)",
    },
    "& .delay-wrap": {
        animationDelay: "60s",
    },
    ".header": {
        color: "$grayscale0"
    },
    ".title": {
        color: "$grayscale0"
    }
});

const Wrapper = styled("div", {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    animation: `${scroll} 120s linear infinite`,
    whiteSpace: "nowrap",
    transform: "translateX(100%)",
    position: "absolute",
    width: "max-content",
    /*"&:hover": {
        animationPlayState: "paused",
    }*/
});
