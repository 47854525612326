import React from "react";

import Editor from "../components/composition-components/box/EditorArticle";
import AutofillEditor from "../components/composition-components/box/AutofillEditor";
import EditorWrapper from '../components/EditorWrapper';
// import EmptyArticle from "../components/common/EmptyArticle";
import Block3x3 from './components/block3x3.tsx';

const BBlock3x3 = props => {
  const { settings } = props;

  const transformArticle = (settings) => {
    return {
      url: settings?.link,
      title: settings?.title_normal,
      backgroundImage: (settings ? settings['background-image'] : undefined),
      media: settings?.media,
      player: (parseInt(settings?.player) === 1),
      podcast: (parseInt(settings?.podcast) === 1)
    }
  }

  const data = [
    {
      header: settings.b1?.title_normal,
      headerLink: settings.b1?.link,
      articles: [
        transformArticle(settings.b2),
        transformArticle(settings.b3),
        transformArticle(settings.b4),
      ],
      tbRegion: props.blockSettings?.tb_region ? props.blockSettings?.tb_region + '_1' : undefined
    },
    {
      header: settings.b5?.title_normal,
      headerLink: settings.b5?.link,
      articles: [
        transformArticle(settings.b6),
        transformArticle(settings.b7),
        transformArticle(settings.b8),
      ],
      tbRegion: props.blockSettings?.tb_region ? props.blockSettings?.tb_region + '_2' : undefined
    }, {
      header: settings.b9?.title_normal,
      headerLink: settings.b9?.link,
      articles: [
        transformArticle(settings.b10),
        transformArticle(settings.b11),
        transformArticle(settings.b12),
      ],
      tbRegion: props.blockSettings?.tb_region ? props.blockSettings?.tb_region + '_3' : undefined
    }
  ]

  return (
    <div className={props.classes + ' pos-relative container'} {...props.events} >
      <EditorWrapper {...props} editor={props.autofill ? AutofillEditor : Editor} />
      <Block3x3 data={data} />
    </div>
  )
}

export default BBlock3x3;