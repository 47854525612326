import React from 'react';
import { styled } from "../../../stitches.config";
import Box from '../Box'

const Wrapper = styled("div", {
	/* dodatkowe style do embedów które zmieniają się w photoOnTop na mobile */
	".photo-on-top-embed": {
		padding: "0 12px"
	},
	variants: {
		wrapper: {
			true: {
				padding: "48px 0 40px",
			},
			false: {}
		}
	},
	defaultVariants: {
		wrapper: true
	}
});

export default props => {
	const isPlaceholder = !!window.APP_SETTINGS?.placeholder;
	return (
		<Wrapper data-tb-region={props.blockSettings.tb_region} wrapper={!props.blockSettings.withoutWrapper}>
			<Box {...props}
				id="b1"
				type="BTextOnPicture"
				settings={props.settings.b1}
				imageHeight={(window.innerWidth > 995 ? 600 : 480)}
				size="lg"
				classes="" />
		</Wrapper>
	);
}
