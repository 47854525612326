import React, { useRef } from 'react';
import { styled } from "../../stitches.config";

import Link from "../../components/Link";
import Media from "../../components/common/Media"

const TextOnPicture = props => {

  const ref = useRef()
  const { article } = props;

  return (
    <BoxWrapper 
      ref={ref} 
      className="text-on-picture-mini" 
    >
      <Link to={article.url}>
        <ImageWrapper preserveImageRatio={props.preserveImageRatio}>
          <Media
            media={props.media}
            src={article.picture.img}
            preserveRatio={false}
            aspectRatio={undefined}
            fetchpriority={props.fetchpriority}
          />
        </ImageWrapper>
      </Link>

      <Link className="link-footer" to={article.url}>
        <Footer className='top-link-footer'>
          <Title className="text-on-picture-mini__title">
            {article.pile_title || article.title}
          </Title>
        </Footer>
      </Link>

    </BoxWrapper>
  );
};


const Title = styled("div",{
  display: "inline",
  fontWeight: 700,
  color: "$grayscale100Fixed",
  backgroundColor: "$grayscale0Fixed",
  fontSize: "20px",
  lineHeight: "27px",
  position: "relative",
  pointerEvents: "none",
  width: "100%",
  whiteSpace: "pre-wrap",
  "@bp4":{
    padding: "0 2px"
  }
});

const BoxWrapper = styled("div",{
  "$$imageHeight": "302px",
  position: "relative",
  height: "auto",
  display: "flex",
  flexDirection: "column",
  marginBottom: "24px",
  "@bp3":{
    overflow: "hidden",
    marginBottom: "32px"
  },
  "@bp4":{
    minHeight: "$$imageHeight"
  },
  "& > a":{
    display: "block",
    textDecoration: "none",
    zIndex: 1,
    inset: 0,
    minWidth: "100%",
    position: "absolute",
    height: "$$imageHeight",
    "@bp4":{
      height: "auto",
      position: "absolute"
    },

    ".link-footer": {
      height: "auto",
      position: "static"
    },

    "circle":{
      transition: "all .2s linear"
    },

    "&:hover":{
      [`& ${Title}`]:{
        color: "$primary",
        textDecoration: "none",
        cursor: "pointer"
      },
      "circle":{
        fill: "#000",
        stroke: "#000"
      }
    },

    "@bp4":{
      paddingBottom: "0"
    }
  }
});

const ImageWrapper = styled("div",{
  position: "relative",
  height: "100%",
  "img":{
    width: "100%",
    height: "302px",
    minHeight: "100%",
    display: "block",
    objectFit: "cover"
  },
  "@bp4":{
    display: "block"
  },
  "& > .preserveImageRatioImage":{
    display: "none",
    "@bp4":{
      width: "100%",
      display: "none",
      minHeight: "$$imageHeight"
    }
  },
  variants: {
    preserveImageRatio: {
      true: {
        "& > .preserveImageRatioImage":{
          "@bp4":{
            display: "block",
            minHeight: "150px"
          }
        }
      }
    }
  }
});

const Footer = styled("div",{
  display: "block",
  marginTop: "16px",
  zIndex: 2,
  pointerEvents: "none",
  "@bp4":{
    maxWidth: "250px",
    position: "absolute",
    left: "15px",
    right: "0",
    bottom: "20px"
  }
});


export default TextOnPicture