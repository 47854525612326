import React from 'react';

import Link from '../editor/Link'
import FileUpload from '../editor/FileUpload'

const BOurPodcastsEditor = (props) => {
    return (
        <div>
            {props.children}
            <Link value={props.settings.link} />
            <hr />
            <div className="form-group">
                <label className="col-sm-3 control-label">Tytuł</label>
                <div className="col-sm-9">
                    <input type="text" name="title" className="form-control" defaultValue={props.settings.title} placeholder="Tytuł" />
                </div>
            </div>
            <div className="form-group">
                <label className="col-sm-3 control-label">Imię i nazwisko</label>
                <div className="col-sm-9">
                    <input type="text" name="name" className="form-control" defaultValue={props.settings.name} placeholder="Imię i nazwisko" />
                </div>
            </div>
            <div className="form-group">
                <label className="col-sm-3 control-label">Kategoria</label>
                <div className="col-sm-9">
                    <input type="text" name="category" className="form-control" defaultValue={props.settings.category} placeholder="Kategoria" />
                </div>
            </div>
            <FileUpload value={props.settings['background-image']} />
        </div>
    );
}


export default BOurPodcastsEditor;