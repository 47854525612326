import React from 'react';
import { styled } from "../../../stitches.config";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Heading from "../../../redesign/atoms/Heading"
import Box from '../Box'

export default props => {
    var settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 0,
        responsive: [
            {
                breakpoint: 1310,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 996,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    centerPadding: '180px',
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    arrows: false,
                }
            },
            {
                breakpoint: 660,
                settings: {
                    centerPadding: '140px',
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    arrows: false,
                }
            },
            {
                breakpoint: 560,
                settings: {
                    centerPadding: '120px',
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    arrows: false,
                }
            },
            {
                breakpoint: 500,
                settings: {
                    centerPadding: '100px',
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    arrows: false,
                }
            },
            {
                breakpoint: 440,
                settings: {
                    centerPadding: '80px',
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    arrows: false,
                }
            },
            {
                breakpoint: 400,
                settings: {
                    centerPadding: '60px',
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    arrows: false,
                }
            },
            {
                breakpoint: 355,
                settings: {
                    centerPadding: '40px',
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    arrows: false,
                }
            },
        ]
    };
    return (
        <Wrapper>
            <section className="container">
                <Heading title={props.settings.b0.title_normal} />
            </section>
            <section className="container container-slider" data-tb-region={props.blockSettings.tb_region}>
                <WrapperSlider >
                    <Slider {...settings}>
                        <Box {...props}
                            id="b1"
                            type="BOurPodcasts"
                            settings={props.settings.b1}
                        />
                        <Box {...props}
                            id="b2"
                            type="BOurPodcasts"
                            settings={props.settings.b2}
                        />
                        <Box {...props}
                            id="b3"
                            type="BOurPodcasts"
                            settings={props.settings.b3}
                        />
                        <Box {...props}
                            id="b4"
                            type="BOurPodcasts"
                            settings={props.settings.b4}
                        />
                        <Box {...props}
                            id="b5"
                            type="BOurPodcasts"
                            settings={props.settings.b5}
                        />
                        <Box {...props}
                            id="b6"
                            type="BOurPodcasts"
                            settings={props.settings.b6}
                        />
                    </Slider>
                </WrapperSlider>
            </section>
        </Wrapper>
    );
}

const Wrapper = styled("div", {
    ".style--": {
        marginBottom: "15px",
        "@bp4": {
            marginBottom: "30px"
        }
    },
    ".container-slider": {
        "@media (max-width: 768px)": {
            width: "auto",
            padding: "0"
        }
    }
});

const WrapperSlider = styled("div", {
    ".slick-slide": {
        position: "relative"
    },
    ".slick-track": {
        paddingTop: "10px"
    },
    ".slick-slide.slick-center .Main_wrap__260iu img, .slick-slide.slick-center a": {
        transform: "scale(1.1)",
        transition: "transform 500ms"
    },
    ".slick-slide .Main_wrap__260iu img, .slick-slide a": {
        transition: "transform 300ms",
        width: "inherit",
        objectFit: "cover"
    },
    ".slick-list": {
        paddingTop: "0px !important",
        paddingBottom: "30px !important",
        "@mobile": {
            ".slick-slide > div": {
                margin: "0 10px"
            }
        }
    },
    ".slick-arrow:before": {
        color: "black"
    }
});