import React, { useEffect, useState } from "react";
import Heading from "./atoms/Heading";
import ArticleList from "./components/ArticleList";

import { styled } from "../stitches.config";
import { useWindowWidth } from "../helpers";
import axios from "axios";
import { useIndexedDB } from "react-indexed-db";
import arrowIcon from "../../src/assets/img/arrow-orange-sm.svg";

const ForYou = (props: any) => {
  const [data, setData] = useState<any[]>([]);
  const [visitedCategories, setVisitedCategories] = useState<any[]>([]);
  const { getAll } = useIndexedDB("visitedCategories");

  const windowWidth = useWindowWidth();
  const isDesktop = window.innerWidth > 996;

  const getTopThreeCategories = () => {
    const topThreeCategory = visitedCategories
      .sort((a, b) => b.count - a.count)
      .slice(0, 3);
    const topIds = topThreeCategory.map(({ categoryId }) => +categoryId);
    const topStringIds = topIds.join(",");
    return topStringIds;
  };

  const fetchFilteredData = async () => {
    const categories = getTopThreeCategories()
    const res = await axios.get(
      `${window.API_HOST
      }/api/for-you?categories=${categories}&limit=7`
    );
    if (res.status !== 200) return;

    setData(res.data);
  };

  useEffect(() => {
    getAll().then((data) => setVisitedCategories(data));
  }, []);

  useEffect(() => {
    fetchFilteredData();
  }, [visitedCategories]);

  if (data.length == 0) {
    return <h1 className="text-center">...</h1>;
  }

  // console.log('ForYouData', data);

  return (
    <section className="container" data-tb-region={props.blockSettings.tb_region}>
      <HeaderWrapper className="row">
        <div className="col-xs-12">
          <Heading url="/dla-ciebie" title="Wybrane dla Ciebie" infoSignText='Wybrane dla Ciebie, to artykuły dostosowane do twoich zainteresowań. Zakładając konto „Twoje naTemat", możesz dodawać ulubione tematy i autorów.' amount="3" />
        </div>
      </HeaderWrapper>

      <ArticleList articles={data} />

      {!isDesktop &&
        <LinkMoreMobile href="/dla-ciebie">
          Więcej z tej kategorii
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.890625 5.01223L2.022 3.88086L7.99773 9.8566L13.9735 3.88086L15.1048 5.01223L7.99773 12.1193L0.890625 5.01223ZM13.9733 4.16387L14.8217 5.01227L7.9976 11.8364L7.99773 11.8365L14.822 5.01223L13.9735 4.1637L13.9733 4.16387ZM2.02183 4.16386L7.99744 10.1395L7.9976 10.1393L2.022 4.1637L2.02183 4.16386Z" />
          </svg>
        </LinkMoreMobile>
      }
    </section>
  );
};


const HeaderWrapper = styled("div", {
  position: "relative",
});

const LinkMore = styled("a", {
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  right: 0,
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "24px",
  color: "$primary",
  paddingRight: "12px",

  "&:hover": {
    color: "#E45A00",
    textDecoration: "none",
  },

  "&:active": {
    color: "#FF6400",
    textDecoration: "none",
  },

  "& .arrowIcon": {
    marginLeft: "6px",
  },
});

const LinkMoreMobile = styled("a", {
  textAlign: "center",
  fontWeight: 600,
  fontSize: "18px",
  lineHeight: "24px",
  letterSpacing: "0.2px",
  color: "$primary",
  borderTop: "1px solid rgba(255, 255, 255, 0.1)",
  marginTop: 16,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  "&:hover": {
    color: "#E45A00",
    textDecoration: "none",
  },

  "&:active": {
    color: "#FF6400",
    textDecoration: "none",
  },

  "& svg": {
    marginLeft: "9px",
    "& path": {
      fill: "$primary",
    }
  },
});

export default ForYou;
