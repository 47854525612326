import React from "react";

import Editor from './EditorArticle'
import EditorWrapper from '../../EditorWrapper'

import Topm from "../../../redesign/components/TextOnPictureMini";
import EmptyArticle from "../../common/EmptyArticle";

const BTextOnPictureMini = props => {
  var article = EmptyArticle();
  const settings = props.settings;
  const imageUrl = (settings['background-image'] ? settings['background-image'] : '');

  console.log('article', article);

  article.title = settings.title_normal;
  article.picture.img = window.MEDIA_DOMAIN + '/' + imageUrl.replace('.', ',780,0,0,0.');
  article.url = settings.link;
  article.partner = settings.partner;
  article.label = settings.label;
  article.icon = settings.icon;
  article.player = settings.player;
  article.stats = (settings.stats ? settings.stats : { shares: '0', comments: '0' });
  article.category = {
    name: (settings.category ? settings.category : settings.label)
  };
  article.categoryColor = settings.categoryColor;

  // partner
  settings.show_partner_logo = true;
  article.partner_logo = settings.partner_logo;

  return (
    <div className={props.classes} {...props.events} data-tb-region-item>
      <EditorWrapper {...props} editor={Editor} />
      <Topm
        blockType={props.block_type}
        article={article}
        size={props.size}
        imageHeight={props.imageHeight}
        preserveImageRatio={props.preserveImageRatio}
        aspectRatio={props.aspectRatio}
        media={settings.media}
        style={settings.background}
        fetchpriority={props.fetchpriority}
      />
    </div>
  );
};

export default BTextOnPictureMini;
