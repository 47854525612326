import React from "react";
import { styled } from "../../stitches.config";
import { h3 } from "../commonStyles/Fonts";
import PhotoOnLeftBlock1x3 from "./PhotoOnLeftBlock1x3"

import Link from "../../components/Link"

export interface Block1x3Props {
  articles: Array<{
    url: string;
    title: string;
    backgroundImage: string;
    media?: any;
    podcast?: any;
    player?: any;
  }>;
  header: string;
  headerLink?: string;
  tbRegion?: string;
}

const Block1x3: React.FC<Block1x3Props> = ({
  articles,
  header,
  headerLink,
  tbRegion
}) => {
  return (
    <StyledBlock1x3 data-tb-region={tbRegion}>
      <HeaderWrapper>
        {headerLink ?
          <Link to={headerLink} className={h3()}>{header}</Link>
          :
          <h3 className={h3()}>{header}</h3>
        }
      </HeaderWrapper>
      <div>
        {articles.map((article, index) => (
          <Block data-tb-region-item>
            <PhotoOnLeftBlock1x3 article={article} key={index} />
          </Block>
        ))}
      </div>
    </StyledBlock1x3>
  );
};

const StyledBlock1x3 = styled("div", {
  /* padding: "33px 0px", */
  "& > div": {
    display: "flex",
    flexDirection: "row",
    flexGrow: "2px",
    overflowX: "scroll",
    scrollbarWidth: "none",
    paddingTop: "15px",

    "@bp4": {
      flexDirection: "column",
      overflowX: "hidden",
      paddingTop: "0",
      paddingBottom: "24px",
    }
  },
});

const Block = styled("div", {
  width: "224px",
  marginRight: "16px",
  position: "relative",
  "@bp4": {
    marginTop: "32px",
    marginRight: "0px",
    width: "auto",
  },
  "a": {
    color: "$grayscale100",
    position: "relative",
    "&:hover": {
      textDecoration: "underline",
      textDecorationColor: "$grayscale100",
    }
  }
});

const HeaderWrapper = styled("div", {
  paddingBottom: "0px !important",
  display: "block !important",
  "& h3, a": {
    display: "block",
    color: "$grayscale100",
    paddingLeft: "20px",
    position: "relative",
    margin: "0",
    "@bp4": {
      marginTop: "0 !important",
      overflowX: "hidden",
    },
    "&:after,&:before": {
      content: "",
      position: "absolute",
      height: "8px",
      width: "8px",
      borderRadius: "50%",
      backgroundColor: "$primary",
    },
    "&:after": {
      left: "0px",
      top: "6px",
    },
    "&:before": {
      left: "0px",
      top: "18px",
    },
  },
})


export default Block1x3;
