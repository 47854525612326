import React from 'react';

import Box from '../Box'
import { Wrapper } from "./ACommonStyles";
import { getPcsUrls } from "./AHelpers";
import { useWindowWidth } from '../../../helpers';

import { styled } from "../../../stitches.config";
import BHotNewsBar from "./BHotNewsBar";


export default props => {
    const blockSettings = props.settings.settings;
    const pcs = getPcsUrls(blockSettings?.pcs_background_mobile, blockSettings?.pcs_background_desktop);
    //const hasPcs = (blockSettings?.pcs_background_mobile?.length > 0 || blockSettings?.pcs_background_desktop?.length > 0);
    const windowWidth = useWindowWidth();
    const isDesktop = window.innerWidth > 996;

    // redakcja chciała cały blok jako jedna rzecz w kompozycji, dlatego zostaje taki układ

    return (
        <Root>
            <section className={props.blockGroupHasBackground ? "container container-with-background" : "container"}>

                {/* przekazywany komponent hot news bar  */}

                <BHotNewsBar settings={props.settings} blockSettings={{ tb_region: blockSettings?.tb_region }} />

                {/* pozycjonowanie bloków: 1m1d1m */}

                <div data-tb-region={props.blockSettings.tb_region}>
                    <Wrapper className="row" pcs={pcs}>
                        {/* test główny jako pierwszy na mobile */}
                        {!isDesktop &&
                            <div className="col-xs-12 col-md-6">
                                <Box {...props}
                                    id="b7"
                                    type="BPhotoOnTop"
                                    settings={props.settings.b7}
                                    classes="" />
                            </div>
                        }
                        <div className="col-xs-12 col-md-3">
                            <Box {...props}
                                id="b6"
                                type={windowWidth > 996 ? "BPhotoOnTop" : "BPhotoOnLeft"}
                                settings={props.settings.b6}
                                showAuthor={true}
                                classes="" />
                        </div>
                        {/* test główny ukrywany jako drugi na mobile */}
                        {isDesktop &&
                            <div className="col-xs-12 col-md-6">
                                <Box {...props}
                                    id="b7"
                                    type="BTextOnPicture"
                                    settings={props.settings.b7}
                                    classes="" />
                            </div>
                        }
                        <div className="col-xs-12 col-md-3">
                            <Box {...props}
                                id="b8"
                                type={windowWidth > 996 ? "BPhotoOnTop" : "BPhotoOnLeft"}
                                settings={props.settings.b8}
                                showAuthor={true}
                                classes="" />
                        </div>
                    </Wrapper>

                    {/* pozycjonowanie bloków "czwórka" */}

                    <Wrapper className="row" pcs={pcs}>
                        <div className="col-xs-12 col-md-3">
                            <Box {...props}
                                id="b9"
                                type={windowWidth > 996 ? "BPhotoOnTop" : "BPhotoOnLeft"}
                                settings={props.settings.b9}
                                classes="" />
                        </div>
                        <div className="col-xs-12 col-md-3">
                            <Box {...props}
                                id="b10"
                                type={windowWidth > 996 ? "BPhotoOnTop" : "BPhotoOnLeft"}
                                settings={props.settings.b10}
                                classes="" />
                        </div>
                        <div className="col-xs-12 col-md-3">
                            <Box {...props}
                                id="b11"
                                type={windowWidth > 996 ? "BPhotoOnTop" : "BPhotoOnLeft"}
                                settings={props.settings.b11}
                                classes="" />
                        </div>
                        <div className="col-xs-12 col-md-3">
                            <Box {...props}
                                id="b12"
                                type={windowWidth > 996 ? "BPhotoOnTop" : "BPhotoOnLeft"}
                                settings={props.settings.b12}
                                classes="" />
                        </div>
                    </Wrapper>
                </div>
            </section>
        </Root>
    );
}

const Root = styled("div", {
    position: "relative",
    marginTop: "32px",
    "@bp4": {
        marginTop: "24px",
    },

    // specjalna zmiana coloru na background
    "& .container-with-background": {
        position: "relative",
        "& .photo-on-top": {
            backgroundColor: "transparent !important",
            "& .title": {
                color: "$grayscale0Fixed",
            },
        },
        "& .photo-on-left": {
            "& span": {
                color: "$grayscale0Fixed",
            },
        },

        "& .b-hot-news-bar": {
            position: "absolute",
            top: "-65px",
            left: 0,
            width: "calc(100% - 24px)",
            margin: "0 12px",
            "@bp4": {
                top: "-64px",
            }
        },
        "& .photo-on-top__author": {
            "& p": {
                color: "$grayscale0Fixed",
            }
        }
    },

    "& .b-hot-news-bar": {
        marginBottom: "12px",
        overflow: "hidden",
    },

    "& .b-hot-news-bar--blocked": {
        display: "none !important",
    },
});
