import React from "react";
import { styled } from "../../../stitches.config";

import Editor from "./BHotNewsBarEditor";
import EditorWrapper from '../../EditorWrapper';

import Link from "../../Link";


const BHotNewsBar = props => {

    const { settings } = props;

    return (
        <>
            <EditorWrapper {...props} editor={Editor} />
            {settings.title_normal ?
                <Wrapper
                    data-tb-region-item
                    css={{
                        "$$headerColor": settings.headerColor
                    }} whiteColor={((settings.headerColor === "#fff") || (settings.headerColor === "#ffffff") || (settings.headerColor === "#FFF") || (settings.headerColor === "#FFFFFF")) ? true : false}>
                    <Link to={settings.link}>
                        <span className="header">{settings.header}</span><span className="title">{settings.title_normal}</span>
                    </Link>
                </Wrapper>
                :
                null
            }
        </>
    )
}

const Wrapper = styled("div", {
    flexShrink: 0,
    position: "relative",
    "&:before": {
        position: "absolute",
        right: "14px",
        top: "10px",
        width: "6px",
        height: "6px",
        backgroundColor: "#3A3A3A",
        borderRadius: "6px",
        display: "flex",
        content: ""
    },
    "a": {
        paddingRight: "22px",
        marginRight: "16px",
        "&:hover": {
            color: "#fff !important",
            textDecoration: "none"
        }
    },
    ".title": {
        fontSize: "18px",
        fontWeight: 600,
        lineHeight: 24,
        letterSpacing: "0.2px",
        textDecoration: "underline"
    },
    ".header": {
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "24px",
        letterSpacing: "0.2px",
        color: "$$headerColor"
    },
    variants: {
        "whiteColor": {
            true: {
                "& .header": {
                    color: "$grayscale0"
                }
            },
            false: {}
        }
    }
});

export default BHotNewsBar;