import React from "react";
import { styled } from "../../stitches.config";
import Avatar from "../atoms/Avatar";
import { h4Strong, body } from "../commonStyles/Fonts";

interface OurPodcastsProps {
  article?: any,
}

const OurPodcasts: React.FC<OurPodcastsProps> = ({
  article
}) => {

  return (
    <StyledOurPodcasts>
      <a className="link-wrapper link-wrapper--active" href={article?.link}>
        <Avatar src={article?.picture.img} />

        <h4 className={h4Strong()}>{article?.title}</h4>
        <StyledName className={body()}>{article?.name}</StyledName>

        {article?.category &&
          <StyledCategory>
            {article?.category}
          </StyledCategory>
        }
      </a>
    </StyledOurPodcasts>
  );
};

const StyledOurPodcasts = styled("div", {
  width: "226px",
  height: "265px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "0 auto",
  "@bp4": {
    width: "236px",
  },
  "@bp5": {
    height: "auto",
    width: "206px",
    minHeight: "282px",
    paddingBottom: "8px",
  },
  "& .link-wrapper": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textDecoration: "none",
    width: "100%",
    height: "100%",
    padding: "32px 16px",
    "&:hover": {
      textDecoration: "none",
    },
    "& a": {
      display: "flex",
      justifyContent: "center",
      textDecoration: "none",
    }
  },
  "& .link-wrapper--active": {
    "&:hover": {
      "@bp5": {
        boxShadow: "0px 0px 8px #99999945",
        transition: "0.3s",
      }
    }
  },
  "& .avatar": {
    width: "100px",
    height: "100px",
    marginBottom: "16px",
    "@bp4": {
      width: "96px",
      height: "96px",
    },
  },
  "& p, h4": {
    margin: 0,
    textAlign: "center",
    color: "$grayscale100",
  },
});

const StyledName = styled("p", {
  margin: "6px 0 16px 0 !important",
  textAlign: "center",
  wordBreak: "break-word",
  textOverflow: "ellipsis",
  "-webkit-box-orient": "vertical",
  display: "-webkit-box",
  "-webkit-line-clamp": "3",

  "@bp4": {
    margin: "6px 0 16px 0 !important",
  },
  a: {
    color: "inherit",
  },
});

const StyledCategory = styled('div', {
  width: "max-content",
  padding: "3px 5px",
  fontFamily: "HK Grotesk",
  fontStyle: "normal",
  fontWeight: "800",
  fontSize: "12px",
  lineHeight: "16px",
  display: "flex",
  alignItems: "center",
  backgroundColor: "$primary",
  color: "$grayscale0",

  "@bp4": {
    padding: "4px 6px",
  },
})

export default OurPodcasts;
