import React from "react";

import Editor from './BOurPodcastsEditor'
import EditorWrapper from '../../EditorWrapper'

import OurPodcasts from "../../../redesign/components/OurPodcasts";
import EmptyArticle from "../../common/EmptyArticle";

const BOurPodcasts = props => {
    let article = EmptyArticle();
    const { settings } = props;
    article.link = settings.link;
    article.category = settings.category;
    article.name = settings.name;
    article.title = settings.title;

    const imageUrl = (settings['background-image'] ? settings['background-image'] : '');
    article.picture.img = window.MEDIA_DOMAIN + '/' + imageUrl.replace('.', ',780,0,0,0.');

    return (
        <div className={props.classes} {...props.events} data-tb-region-item>
            <EditorWrapper {...props} editor={Editor} />
            <OurPodcasts
                article={article}
                {...props}
            />
        </div>
    );
};


export default BOurPodcasts;