import React from "react";
import { styled } from "../../../stitches.config";

export const Wrapper = (props)=>{ 

	const { pcs, className, children } = props; 

	let style = {};
	if(pcs.mobile)
		style = {
			...style,
			background: `url(${pcs.mobile}) center no-repeat`,
			padding: "70px 0 15px 0"
		};
	
	if(pcs.desktop)
		style = {
			...style,
			"@bp4":{
				background: `url(${pcs.desktop}) center no-repeat`,
				padding: "70px 0 15px 0"
			}
		}

	return (
		<WrapperComponent
			className={`commonWrapper ${className ?? ""}`}
			css={style}
		>
			{children}
		</WrapperComponent>
	);

};

Wrapper.toString = ()=> ".commonWrapper";

const WrapperComponent = styled("div",{
	backgroundSize: "cover"
});